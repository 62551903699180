import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import Slider, { Settings, CustomArrowProps } from 'react-slick'
import { Container } from "./styles"
import { DealershipWarning, ReferralLinkCard } from "./components"
import { useTheme } from "@emotion/react"

export const EmphasisBanners: React.FC = () => {
  const theme = useTheme()

  const SampleNextArrow = (props: CustomArrowProps) => {
    const { className, onClick, currentSlide } = props

    return (
      <button
        className={className}
        onClick={onClick}
      >
        <IconChevronRight size={24} color={currentSlide === 1 ? theme.palette.secondary.main : theme.palette.primary.main} />
      </button>
    )
  }

  const SamplePrevArrow = (props: CustomArrowProps) => {
    const { className, onClick, currentSlide } = props
    
    return (
      <button
        className={className}
        onClick={onClick}
      >
        <IconChevronLeft size={24} color={currentSlide === 1 ? theme.palette.secondary.main : theme.palette.primary.main} />
      </button>
    )
  }

    const settings: Settings = {
      arrows: true,
      infinite: true,
      autoplay: true,
      fade: true,
      autoplaySpeed: 5000,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      verticalSwiping: false,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
      dots: false,
    }

  return (
    <Container>
      <Slider {...settings}>
        <ReferralLinkCard />
        <DealershipWarning />
      </Slider>
    </Container>
  )
}