import { CSSProperties, useMemo } from 'react'
import { Box, Link, Typography, useMediaQuery } from '@mui/material'
import { Card } from '../Card'
import { theme } from '~/utils'

export type IconTextListItem = {
  icon: React.JSX.Element,
  text: React.JSX.Element
  invert?: boolean
  link?: string
}

export type BannerProps = {
  backgroundImage?: string
  backgroundColor?: CSSProperties['backgroundColor']
  category?: {
    text: string,
    color?: string
    icon?: React.JSX.Element | null
    
  },
  title: React.JSX.Element
  description?: React.JSX.Element
  iconTextList?: IconTextListItem[]
  rightElement?: React.JSX.Element | null
  buttonsContainer?: React.JSX.Element
  minHeight?: CSSProperties['minHeight']
  padding?: CSSProperties['padding']
}

export const Banner: React.FC<BannerProps> = ({ backgroundImage, category, title, description, iconTextList, rightElement, backgroundColor, buttonsContainer, minHeight = '500px', padding }) => {
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const iconTextItemsList = useMemo(() => {
    return iconTextList?.map((item, index) => {
      return (
        
        <Box key={`${item.text.toString()}-${index}`} sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: theme.palette.grey['400'] }}>
          <Link href={item.link || ''} target='_blank'  sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: theme.palette.grey['400'], textDecoration: 'none', cursor: item.link ? 'pointer' : 'auto'  }}>
            {!item.invert && item.icon}
            <Typography sx={{ fontWeight: 400, fontSize: isLowerSm ? '.9rem' : '1rem' }}>
              {item.text}
            </Typography>
            {item.invert && item.icon}
          </Link>
        </Box>
      )
    })
  }, [iconTextList, isLowerSm])

  return (
    <Card sx={{
      width: `100%`,
      minHeight,
      padding: padding || (isLowerSm ? '2rem 1rem' : '3rem 2rem'),
      border: `1px solid ${theme.palette.grey['300']}`,
      background: `${backgroundColor || '#FFF'} url(${backgroundImage}) ${isLowerMd ? 'center left' : 'center right'}/1160px no-repeat`,
    }}>
      <Box sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          flex: 1,
        }}>
          {category && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', color: category.color || theme.palette.grey['400'] }}>
              {category.icon}
              <Typography sx={{ fontWeight: 500, fontSize: '.95rem' }}>{category.text}</Typography>
            </Box>
          )}

          <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
            {title}
            {description}
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
            {iconTextItemsList}
          </Box>

          {buttonsContainer}
        </Box>

        {!isLowerMd && rightElement && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}>
            {rightElement}
          </Box>
        )}
      </Box>
    </Card>
  )
}
