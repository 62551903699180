import { SvgIcon, SvgIconProps } from '@mui/material'

export const Bell: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon width={135} height={135} viewBox="0 0 135 135" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M69.4251 11.3024C69.5279 11.6657 69.6168 12.0301 69.6908 12.3945C69.7168 12.5204 69.7404 12.6468 69.763 12.7733C71.097 20.2646 66.5469 27.7173 59.074 29.8338C51.6012 31.9504 43.8188 27.991 41.0266 20.912C40.9798 20.7925 40.9336 20.6724 40.8897 20.5517C40.7615 20.2029 40.646 19.846 40.5432 19.4827C38.2881 11.5201 42.9323 3.2076 50.8949 0.952461C58.8574 -1.30268 67.1706 3.34043 69.4257 11.3024H69.4251Z" fill="#FFC200"/>
      <path d="M101.696 106.927C101.8 107.291 101.894 107.655 101.98 108.021C102.312 109.428 102.517 110.836 102.602 112.225C102.606 112.296 102.611 112.365 102.614 112.435C103.136 122.363 97.5434 131.4 88.5592 133.944C79.5647 136.492 70.0521 131.715 65.2958 122.968C65.2675 122.918 65.2403 122.866 65.2126 122.814C64.5566 121.586 63.9929 120.281 63.5379 118.908C63.4189 118.552 63.3086 118.192 63.2052 117.827C63.0256 117.194 62.8726 116.56 62.7455 115.926C60.4702 104.655 66.2775 93.6591 76.3422 90.8085C86.4069 87.958 97.1172 94.2752 101.09 105.066C101.314 105.672 101.517 106.292 101.696 106.925L101.696 106.927Z" fill="url(#paint0_linear_24158_18319)"/>
      <g style={{ mixBlendMode: 'screen' }}>
      <path d="M90.4085 130.805C89.6196 131.175 88.7944 131.485 87.9327 131.73C80.0845 133.953 71.7962 129.961 67.4199 122.523C69.1345 122.156 70.8757 121.759 72.6371 121.336C76.5606 127.807 83.4692 131.59 90.4079 130.805H90.4085Z" fill="#686868"/>
      </g>
      <path d="M124.949 76.8847C122.726 80.0153 118.999 82.9415 114.136 85.691C105.32 90.6765 92.7691 95.0811 78.6816 99.0705C68.301 102.011 58.1959 104.355 49.4035 105.473C44.6327 106.08 40.2489 106.325 36.4161 106.109C32.9857 105.916 29.9977 105.353 27.5688 104.348C28.7786 100.975 30.2726 96.7166 31.3791 90.8405C31.9312 87.9091 32.3863 84.5758 32.6606 80.7487C33.0787 74.9189 30.4609 68.929 28.1815 60.8816C18.759 27.6129 55.4932 17.2179 55.4984 17.2145C55.5053 17.2145 92.2343 6.80389 101.657 40.0725C101.738 40.3601 101.818 40.646 101.896 40.9295C104.005 48.5808 104.969 54.7624 108.261 59.3379C110.525 62.4859 112.681 65.1071 114.707 67.3282C118.724 71.734 122.158 74.6532 124.949 76.8853L124.949 76.8847Z" fill="url(#paint1_linear_24158_18319)"/>
      <path d="M134.689 84.8333C134.685 84.9015 134.681 84.9708 134.677 85.0406C134.469 88.3284 132.699 91.4318 129.674 94.3678C121.427 102.375 103.856 109.135 83.2226 114.979C56.1211 122.655 30.7965 126.496 23.7106 116.469C23.6228 116.345 23.5385 116.222 23.4571 116.1C20.4506 111.563 22.9402 105.844 27.5683 104.345C28.5864 104.016 29.7079 103.89 30.8982 104.027C32.7358 104.237 34.7178 104.329 36.8245 104.314C40.6019 104.286 44.7819 103.913 49.2482 103.266C58.0309 101.994 67.9263 99.6583 78.0701 96.7853C91.1389 93.0841 102.979 89.0144 112 84.4943C115.925 82.5274 119.316 80.4749 122.042 78.3307C122.955 77.6123 123.942 77.1387 124.949 76.8811C129.737 75.6563 134.964 79.3182 134.689 84.8327L134.689 84.8333Z" fill="#E87610"/>
      <g style={{ mixBlendMode: 'screen' }}>
      <path d="M47.0134 55.1031C49.2628 63.0449 51.5826 69.0301 51.761 74.6151C52.3021 91.4573 49.3309 97.9479 49.2477 103.262C44.7814 103.909 40.602 104.282 36.824 104.31C38.2238 99.1185 41.6467 91.8968 42.4015 77.2659C42.6972 71.5463 40.166 65.6211 37.9166 57.6794C37.8756 57.5344 37.8352 57.3895 37.7965 57.2457C33.9174 43.0524 40.2474 28.0496 53.0569 20.8106C53.27 20.6905 53.4831 20.5709 53.6951 20.4531C47.651 25.8337 41.6571 36.1877 47.014 55.1026L47.0134 55.1031Z" fill="#999999"/>
      </g>
      <g style={{ mixBlendMode: 'screen' }}>
      <path d="M44.1988 10.8781C44.8537 11.6768 46.6156 9.88478 48.999 7.92995C51.3823 5.97511 53.4833 4.5972 52.8284 3.79851C52.1735 2.99983 49.0094 3.08299 46.6266 5.03783C44.2438 6.99267 43.5439 10.0794 44.1988 10.8775V10.8781Z" fill="#686868"/>
      </g>
      <g style={{ mixBlendMode: 'screen' }}>
      <path d="M72.6559 109.057C72.4324 107.002 62.9672 108.569 51.2191 109.846C39.4716 111.122 29.8903 111.624 30.1132 113.679C30.3367 115.734 40.2801 118.563 52.0276 117.287C63.7751 116.011 72.8788 111.111 72.6553 109.057H72.6559Z" fill="#686868"/>
      </g>
      <g style={{ mixBlendMode: 'color-burn' }}>
      <path d="M134.69 84.8243C134.687 84.8924 134.683 84.9617 134.679 85.0316C134.471 88.3193 132.7 91.4228 129.676 94.3588L114.137 85.679L112.001 84.4859L91.5749 73.0762L90.2374 72.3289C85.387 69.4068 81.594 64.7076 79.9296 58.8299C77.183 49.1319 81.0534 39.125 88.8491 33.6289L101.897 40.9175C104.006 48.5688 104.971 54.7504 108.262 59.3259C110.526 62.4739 112.683 65.0951 114.708 67.3162C118.725 71.722 122.159 74.6412 124.95 76.8733C129.738 75.6484 134.965 79.3103 134.69 84.8249V84.8243Z" fill="#D3D3D3"/>
      </g>
      <path d="M124.418 46.2319C125.151 48.8191 125.413 51.4277 125.255 53.9669C124.663 63.487 118.173 72.03 108.474 74.7766C102.596 76.4415 96.6066 75.6757 91.5749 73.0781C91.121 72.8443 90.6746 72.5948 90.2374 72.3309C85.387 69.4087 81.594 64.7096 79.9296 58.8318C77.183 49.1338 81.0534 39.1269 88.8491 33.6309C90.9287 32.1652 93.2872 31.02 95.8744 30.2871C108.16 26.8077 120.94 33.9462 124.419 46.2319H124.418Z" fill="#EA3606"/>
      <g style={{ mixBlendMode: 'screen' }}>
      <path d="M85.3736 60.6333C82.809 51.5781 86.4224 42.2353 93.7018 37.1037C95.6433 35.735 97.8453 34.6661 100.261 33.9817C103.559 33.0479 106.895 32.9336 110.052 33.5168C105.921 31.8022 101.203 31.4159 96.5656 32.7297C94.2625 33.3823 92.1621 34.4016 90.3113 35.7067C83.3703 40.5999 79.9249 49.509 82.3701 58.1432C83.6833 62.7805 86.4853 66.5926 90.1114 69.2109C87.9597 66.8293 86.308 63.9303 85.3742 60.6328L85.3736 60.6333Z" fill="#686868"/>
      </g>
      <path d="M32.5166 19.7053C32.6986 19.8936 32.8522 20.1153 32.9677 20.3665C33.5175 21.5637 32.9931 22.9792 31.7959 23.5289C25.6288 26.3616 20.9355 31.4216 18.581 37.7759C16.2265 44.1301 16.4904 51.026 19.3231 57.1937C19.8729 58.3909 19.3485 59.8063 18.1513 60.3561C16.9542 60.9059 15.5387 60.3815 14.9889 59.1844C13.2888 55.4832 12.3642 51.5602 12.24 47.5246C12.1199 43.6271 12.7488 39.7896 14.1088 36.119C15.4688 32.4484 17.4918 29.1278 20.1223 26.249C22.8458 23.2685 26.1035 20.895 29.8047 19.1948C30.7501 18.7605 31.8323 18.9967 32.5161 19.7053H32.5166Z" fill="url(#paint2_linear_24158_18319)"/>
      <path d="M27.5396 8.86936C27.7215 9.05763 27.8751 9.27939 27.9906 9.5306C28.5404 10.7278 28.016 12.1432 26.8189 12.693C8.12463 21.2798 -0.0983893 43.4749 8.48846 62.1692C9.03824 63.3663 8.51387 64.7818 7.31671 65.3315C6.11955 65.8813 4.7041 65.357 4.15432 64.1598C1.78195 58.9946 0.491239 53.5205 0.317989 47.8899C0.150514 42.451 1.02831 37.0964 2.92598 31.9746C4.82364 26.8527 7.64704 22.2189 11.3176 18.2018C15.1182 14.0432 19.6637 10.7312 24.8288 8.35886C25.7742 7.92458 26.8564 8.16077 27.5402 8.86936H27.5396Z" fill="url(#paint3_linear_24158_18319)"/>
      <path d="M100.651 56.6589L98.1544 42.1429C98.114 41.8992 98.0938 41.6554 98.0938 41.4117C98.0938 39.3275 99.4538 38.2852 102.174 38.2852C104.895 38.2852 106.255 39.3206 106.255 41.3915C106.255 41.6757 106.235 41.9263 106.194 42.1429L103.697 56.6589H100.652H100.651ZM102.174 59.6434C104.556 59.6434 105.747 60.8348 105.747 63.2164C105.747 65.598 104.555 66.7894 102.174 66.7894C99.7922 66.7894 98.6008 65.5986 98.6008 63.2164C98.6008 60.8342 99.7916 59.6434 102.174 59.6434Z" fill="white"/>
      <defs>
      <linearGradient id="paint0_linear_24158_18319" x1="88.5459" y1="133.946" x2="76.332" y2="90.8113" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F29550"/>
      <stop offset="0.16" stop-color="#F1934D"/>
      <stop offset="0.25" stop-color="#EE8E46"/>
      <stop offset="0.31" stop-color="#EA8438"/>
      <stop offset="0.37" stop-color="#E47725"/>
      <stop offset="0.42" stop-color="#DD660D"/>
      <stop offset="0.44" stop-color="#D95D00"/>
      </linearGradient>
      <linearGradient id="paint1_linear_24158_18319" x1="78.6438" y1="99.0796" x2="55.4661" y2="17.2258" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF8D2B"/>
      <stop offset="0.04" stop-color="#FF9227"/>
      <stop offset="0.27" stop-color="#FFAD16"/>
      <stop offset="0.51" stop-color="#FFC009"/>
      <stop offset="0.75" stop-color="#FFCC02"/>
      <stop offset="1" stop-color="#FFD000"/>
      </linearGradient>
      <linearGradient id="paint2_linear_24158_18319" x1="-1.18815" y1="37.9937" x2="39.0443" y2="40.8576" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF8D2B"/>
      <stop offset="0.04" stop-color="#FF9227"/>
      <stop offset="0.27" stop-color="#FFAD16"/>
      <stop offset="0.51" stop-color="#FFC009"/>
      <stop offset="0.75" stop-color="#FFCC02"/>
      <stop offset="1" stop-color="#FFD000"/>
      </linearGradient>
      <linearGradient id="paint3_linear_24158_18319" x1="-1.02013" y1="35.6376" x2="39.2123" y2="38.5014" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FF8D2B"/>
      <stop offset="0.04" stop-color="#FF9227"/>
      <stop offset="0.27" stop-color="#FFAD16"/>
      <stop offset="0.51" stop-color="#FFC009"/>
      <stop offset="0.75" stop-color="#FFCC02"/>
      <stop offset="1" stop-color="#FFD000"/>
      </linearGradient>
      </defs>
    </SvgIcon>
  )
}
