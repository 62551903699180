import { Box, Divider, Typography, useMediaQuery } from '@mui/material'
import { Switch } from '~/components'
import { theme } from '~/utils'

export type PermissionItemProps = {
  icon: React.JSX.Element
  title: string
  description: string
  name: string
}

export const PermissionItem: React.FC<PermissionItemProps> = ({ icon, title, description, name }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', height: 40, width: 40, borderRadius: '50%', background: '#FAFAFA', padding: 1 }}>
          {icon}
        </Box>

        <Typography variant={isLowerSm ? 'body2' : 'body1'} fontWeight={600}>{title}</Typography>
      </Box>

      <Divider />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 3 }}>
        <Typography variant={isLowerSm ? 'body3' : 'body1'} sx={{ padding: isLowerSm ? '8px' : '0px 32px 8px 32px' }}>
          {description}
        </Typography>

        <Switch name={name} />
      </Box>
    </Box>
  )
}
