import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { theme } from '~/utils'

const createEmotionCache = () => {
  return createCache({ key: 'css', prepend: true })
}

type ThemeDialogProps = {
  children: React.JSX.Element
}

const cache = createEmotionCache()

export const ThemeDialog = (props: ThemeDialogProps) => (
  <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      { props.children }
    </ThemeProvider>
  </CacheProvider>
)
