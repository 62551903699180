import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { Layout, PageLoader } from './components'
import { UsersProvider, useAuth, IndicationsProvider, ComissionsProvider, usePartner, IndicationFlowProvider, DealershipsPartnerProvider } from './contexts'
import {
  BenefitsSettings,
  Commissions,
  Dashboard,
  DeveloperSettings,
  FirstAccess,
  GeneralSettings,
  Home,
  Login,
  RecoverPassword,
  Indications,
  IndicationsCreate,
  Rewards,
  UsersCreateEdit,
  UsersList,
  IndicationsCreateV2
} from './pages'

const PrivateRoute = () => {
  const { authUser, getUserIsLoading } = useAuth()
  const { getPartnerIsLoading, myPartner } = usePartner()

  if (getUserIsLoading || getPartnerIsLoading) return <PageLoader />

  if (authUser && myPartner) return <Layout><Outlet /></Layout>

  return <Navigate to='/' />
}


export const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/first-access' element={<FirstAccess />} />
      <Route path='/recover-password' element={<RecoverPassword />} />

      <Route path='app/*' element={<PrivateRoute />}>
        <Route index element={<Home />} />

        <Route path='indications' element={<DealershipsPartnerProvider><IndicationsProvider><Outlet /></IndicationsProvider></DealershipsPartnerProvider>}>
          <Route index element={<Indications />} />
          <Route path='create' element={<IndicationsCreate />} />
          <Route path='update/:id' element={<IndicationFlowProvider><IndicationsCreateV2 /></IndicationFlowProvider>} />
        </Route>

        <Route path='settings' element={<Outlet />}>
          <Route index element={<GeneralSettings />} />
          <Route path='developer' element={<DeveloperSettings />} />
          <Route path='benefits' element={<BenefitsSettings />} />
        </Route>

        <Route path='users' element={<UsersProvider><Outlet /></UsersProvider>}>
          <Route index element={<UsersList />} />
          <Route path='create' element={<UsersCreateEdit />} />
          <Route path='update/:id' element={<UsersCreateEdit />} />
        </Route>

        <Route path='commissions' element={<ComissionsProvider><Outlet /></ComissionsProvider>}>
          <Route index element={<Commissions />} />
        </Route>
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='rewards' element={<Rewards />} />
        <Route path='*' element={<Navigate to='/app' />} />
      </Route>
    </Routes>
  )
}
