import styled from '@emotion/styled'
import { theme } from '~/utils'

export const Container = styled.div`
  position: relative;
  display: flex;
  min-height: 400px;

  .slick-slider {


    display: grid;
    grid-template-columns: 100fr 1fr;
    margin: 0 24px;
    /* @media(max-width: ${theme.breakpoints.values.sm}px) {
      grid-template-columns: 100fr 1fr;
    } */
  }

  .slick-container {
    display: flex;
    flex: 1;
    height: 100%;
  }

  .slick-slide {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;

    div {
      display: flex;
      flex: 1;
    }
  }

  .slick-list {
    display: flex;
    flex: 1;
    margin: 0 -24px;
  }

  .slick-track {
    display: flex !important;
    flex-direction: row;
    align-items: strech;
    width: 360px;
    flex: 1;
  }

  .slick-prev {
    right: 70px;
  }

  .slick-next {
    right: 20px;
  }

  .slick-arrow {
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    border: 0;
    border-radius: 50%;
    background: transparent;
    transition: background .1s ease-in-out;
    top: unset;
    bottom: 30px;

    margin: auto 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(03,03,03,0.1);
    }
  }
`
