import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { IconCopy, IconLink, IconShare } from '@tabler/icons-react'
import { theme } from '~/utils'
import { PaperPlaneWithMail } from '~/assets/illustrations'
import { copyToClipboard } from '~/utils/clipboard'
import { Dropdown, ShareSocialMediaLinks } from '~/components'
import { usePartner } from '~/contexts'

export const ReferralLinkCard: React.FC = () => {
  const { indicationLink } = usePartner()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{
      gap: 3,
      padding: isLowerSm ? 2 : 4,
      display: 'flex',
      background: '#FFFBE5',
      borderRadius: '12px',
      position: 'relative',
      flexDirection: 'column',
      border: '1px solid #F9DC45',
      flexGrow: 1      
    }}>
      {!isLowerSm && <PaperPlaneWithMail style={{ position: 'absolute', top: '0px', right: '16px', fontSize: 150 }} />}

      <Box sx={{
        padding: 2,
        display: 'flex',
        borderRadius: '8px',
        alignItems: 'center',
        width: 'fit-content',
        background: '#FFF2AD',
        justifyContent: 'center',
        maxHeight: '55px'
      }}>
        <IconLink />
      </Box>

      <Typography variant='h3' fontWeight={600}>
        Link de indicação <span style={{ fontSize: '16px', color: theme.palette.grey['400'] }}> {isLowerSm && <br />}Copie e cole onde desejar</span>
      </Typography>

      <Typography variant={isLowerSm ? 'body2' : 'h5'} sx={{ marginTop: 1, marginBottom: 2, lineHeight: isLowerSm ? '150%' : '30px', maxWidth: isLowerSm ? '100%' : '80%' }}>
        Envie o link para indicar alguém/alguma empresa para a Desperta{!isLowerMd && <br />} e receba recompensas a cada adesão gerada pelo seu link indicação pessoal:
      </Typography>

      <Box sx={{
        gap: 3,
        display: 'flex',
        alignItems: 'start',
        flexWrap: 'wrap',
        flexFlow: isLowerMd ? 'column' : 'row',
        paddingBottom: isLowerSm ? '3rem' : '0'
      }}>
        <Button onClick={() => copyToClipboard(indicationLink)} startIcon={<IconCopy size={24} />}>COPIAR LINK</Button>
        <Dropdown $options={{ left: isLowerSm ? '0px' : '110%', top: isLowerSm ? '60px' : '8px' }} elementClick={<Button sx={{ boxSizing: 'content-box' }} endIcon={<IconShare size={24} />} color='secondary'>Compartlihar</Button>}>
          <ShareSocialMediaLinks />
        </Dropdown>
      </Box>
    </Box>
  )
}
