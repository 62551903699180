import { Box, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { Card } from '~/components'
import { theme } from '~/utils'

export type PerformanceCardProps = {
  title: string
  value: string
  icon: React.JSX.Element
  description: string
  isLoading?: boolean
}

export const PerformanceCard: React.FC<PerformanceCardProps> = ({ title, description, icon, value, isLoading = false }) => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Card sx={{
      gap: 1,
      padding: 3,
      flexGrow: 1,
      justifyContent: 'center',
      borderLeft: `6px solid ${theme.palette.yellow.light}`
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'space-between'
      }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {isLoading ? 
            <Skeleton variant='text' width={140} />
            : (
              <Typography color={theme.palette.grey['700']}>
                {title}
              </Typography>
            )}
          {isLoading ? 
            <Skeleton variant='text' width={100} />
            : (
              <Typography variant='body2' color={theme.palette.grey['400']}>
                {description}
              </Typography>

            )}

          {isLoading ? 
            <Skeleton variant='rounded' width={40} height='40px' />
            : (
              <Typography variant={isLowerSm ? 'h3' : 'h2'} fontWeight={500} marginTop='8px'>
                {value}
              </Typography>
            )}
        </Box>
        
        {isLoading ? 
          <Skeleton variant='rounded' width={48} height='48px' />
          : (
            <>
              <Box sx={{
                padding: 1.5,
                display: 'flex',
                borderRadius: '8px',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(86, 24, 133, 0.06)'
              }}>
                { icon }
              </Box>
            </>
          )}
      
      </Box>
    </Card>
  )
}
